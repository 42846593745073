import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./Comments.css";

const Comments = () => {
  return (
    <div className="testimonials">
      <Container>
        <h1 className=" mb-2">Client Testimonials</h1>
        <Row>
          <h4>
            Testimonials for EstimatorPro: Your Go-To Instant Estimation
            Solution
          </h4>
          <p>
            At EstimatorPro, we understand the critical importance of accurate
            and swift cost estimations in the world of construction and project
            management. Our revolutionary platform has been transforming the way
            professionals approach estimates, and our valued clients are eager
            to share their experiences.
          </p>{" "}
          <p>
            Here's what the Top Industry Technical Professionals have to say
            about EstimatorPro:
          </p>
        </Row>
        <Row>
          <Col md="4">
            <div className="testimonial">
              <p className="testimonial-text">
                "EstimatorPro has truly changed the game for me. As a technical
                manager, time is of the essence, and my company customers need
                reliable estimates on the go. With EstimatorPro, They can
                quickly generate accurate estimates right from their mobile
                device. The integration with the latest Schedule of Rates (SSR)
                ensures that their estimates are always up to date. The digital
                sign feature is the cherry on top, streamlining the approval
                process for my team. It's like having a virtual estimating
                assistant that never lets down!"
              </p>
              <p className="testimonial-author">
                - Nikhil Singh Jamwal, National Technical Manager, Grihum
                Housing Finance Ltd.
              </p>
            </div>
          </Col>
          <Col md="4">
            <div className="testimonial">
              <p className="testimonial-text">
                "Gone are the days of juggling spreadsheets and manually
                updating rates. EstimatorPro has simplified life of my company
                customers. The ease of use combined with the instant results
                have saved time of my company customers countless hours. The
                fact that they can access it from anywhere means they can stay
                productive even when required in emergency. The digital sign
                feature also adds a layer of professionalism to the estimates.
                Thank you, EstimatorPro!"
              </p>
              <p className="testimonial-author">
                - Uddhav Taur, Technical Manager, Vaastu Housing Finance Corp.
                Ltd.
              </p>
            </div>
          </Col>
          <Col md="4">
            <div className="testimonial">
              <p className="testimonial-text">
                "Accuracy is paramount in my line of work, and EstimatorPro has
                exceeded my expectations. The platform's integration with the
                latest Schedule of Rates ensures that the estimates are always
                aligned with industry standards. The interface is intuitive and
                user-friendly, making it easy for someone like my company
                customers, who isn't a numbers person, to navigate with
                confidence. EstimatorPro has become an indispensable tool in my
                toolkit."
              </p>
              <p className="testimonial-author">
                - Firoj Patel, Technical Manager, ICICI Housing Finance Company
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Comments;
